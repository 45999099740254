@import '../../../assets/css/_config.scss';

.Sidebar {
  margin-inline-end: calc(-1 * var(--container-padding-x));
  margin-inline-start: calc(-1 * var(--container-padding-x));
  display: flex;
  flex-direction: column;
  row-gap: 1em;

  @include media-breakpoint-up('md') {
    margin-inline-end: initial;
    margin-inline-start: initial;
    position: sticky;
    top: var(--container-padding-x);
    z-index: 1;
  }
}

.Sidebar-content {
  display: grid;
  row-gap: rem(24px);
  background-color: var(--c-grey-light);
  padding: rem(32px);

  @include media-breakpoint-up('md') {
    row-gap: rem(40px);
    padding: rem(48px) rem(56px);
  }
}

.Sidebar-list {
  display: grid;
  row-gap: rem(24px);
  list-style: none;
  padding: 0;
  margin: 0;

  @include media-breakpoint-up('md') {
    row-gap: rem(40px);
  }
}
