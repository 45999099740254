@import '../../../assets/css/_config.scss';

.Teaser {
  display: flex;
  gap: rem(24px);
  padding: rem(24px) 0 rem(32px);
  border-bottom: 1px solid rgba(var(--c-black-rgb), 0.1);

  @include media-breakpoint-up('md') {
    padding: rem(48px) 0;
  }

  &.is-highlight {
    grid-column-start: span 2;

    .Teaser-text {
      @include media-breakpoint-up('md') {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: rem(100px);
        grid-row-gap: rem(40px);
      }
    }

    .Teaser-title {
      font-size: rem(20px);

      @include media-breakpoint-up('md') {
        font-size: rem(36px);
      }
    }

    .Teaser-subtitle {
      margin-top: rem(16px);
    }
  }
}

.Teaser-text {
  display: flex;
  flex-direction: column;
  row-gap: rem(24px);
  flex-grow: 1;
}

.Teaser-inner {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: rem(32px);

  @include media-breakpoint-up('md') {
    flex-direction: row;
  }
}

.Teaser-media {
  min-width: rem(36px);
  color: var(--c-primary);
  display: flex;
  justify-content: center;
  text-align: center;

  @include media-breakpoint-up('md') {
    min-width: rem(90px);
  }
}

.Teaser-actions {
}

.Teaser-subtitle,
.Teaser-description {
  font-size: rem(15px);
}

.Teaser-subtitle {
  font-family: var(--fontFamily-primary);
  font-weight: var(--fontWeight-normal);
  opacity: 0.4;
  margin-top: rem(8px);
}

.Teaser-title {
  font-family: var(--fontFamily-secondary);
  font-weight: var(--fontWeight-bold);
  color: var(--c-black);
  text-decoration: none;
  font-size: rem(14px);

  @include media-breakpoint-up('md') {
    font-size: rem(20px);
  }
}

a,
button {
  &.Teaser-title {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.Teaser-content {
}

.Teaser-tags {
  display: flex;
  flex-wrap: wrap;
  gap: var(--t-gutter-xs);
  margin-top: rem(16px);
}
